<!--
 * @Description: 我的收藏页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 17:22:56
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-12 19:34:00
 -->
 <template>
     <div class="collect">
        <el-form ref="form" :model="form" label-width="80px">
  <el-form-item label="店铺名称">
    <el-input v-model="form.name"></el-input>
  </el-form-item>
  <el-form-item label="申请人">
    <el-input v-model="form.p"></el-input>
  </el-form-item>
  <el-form-item label="联系方式">
    <el-input v-model="form.num"></el-input>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="onSubmit">立即申请</el-button>
    <el-button>取消</el-button>
  </el-form-item>
</el-form>
    </div>
  </template>




  <script>
  export default {
    data() {
      return {
        form: {
            name: '',
            p: '', // 申请人
            num: '' // 联系方式
        }
      }
    },
    methods: {
      onSubmit() {
        this.form.name = '';
        this.form.p = '';
        this.form.num = '';
        this.$alert('我们会尽快与您联系', '申请成功', {
          confirmButtonText: '确定',
        });
       
      },
    }
  }
</script>
  <style>
  .collect {
    background-color: #f5f5f5;
  }
  .collect .collect-header {
    height: 64px;
    background-color: #fff;
    border-bottom: 2px solid #ff6700;
  }
  .collect .collect-header .collect-title {
    width: 1225px;
    margin: 0 auto;
    height: 64px;
    line-height: 58px;
    font-size: 28px;
  }
  .collect .content {
    padding: 20px 0;
    width: 1225px;
    margin: 0 auto;
  }
  .collect .content .goods-list {
    margin-left: -13.7px;
    overflow: hidden;
  }
  /* 收藏列表为空的时候显示的内容CSS */
  .collect .collect-empty {
    width: 1225px;
    margin: 0 auto;
  }
  .collect .collect-empty .empty {
    height: 300px;
    padding: 0 0 130px 558px;
    margin: 65px 0 0;
    /* background: url(../assets/imgs/cart-empty.png) no-repeat 124px 0; */
    color: #b0b0b0;
    overflow: hidden;
  }
  .collect .collect-empty .empty h2 {
    margin: 70px 0 15px;
    font-size: 36px;
  }
  .collect .collect-empty .empty p {
    margin: 0 0 20px;
    font-size: 20px;
  }
  /* 收藏列表为空的时候显示的内容CSS END */
  </style>